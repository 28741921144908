@import url('https://fonts.googleapis.com/css2?family=Slackey&family=Viga&display=swap');

:root{
  --ff-serif:'Viga', sans-serif;
  --ff-logo: 'Slackey', cursive;

  --clr-primary: #0059FF;
  --clr-accent: #00FFF0;

}

body {
  margin: 0;
  font-family: var(--ff-serif), serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--ff-serif), serif;
}

header{
text-align: center;

}
nav ul{
  display:flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: space-around;
}
nav a{
  display:inline-block;
  padding: .5em;
  text-decoration: none;
  color: var(--clr-accent);
  font-size: 2rem;
}

h1.logo a{
  color: var(--clr-primary);
  font-size: 5rem;
  font-family: var(--ff-logo);
  text-decoration: none;

}

.section-content{
  text-align: center;
  font-size: 3rem;

}

.wrapper{
  width: 960px;
  margin: 0 auto;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

h2.section-title{
 text-align: center;
  color: var(--clr-primary);
  font-size: 5rem;
}
.span__blue{
  color: var(--clr-primary);
}

.span__accent{
  color: var(--clr-accent)
}

.span__imp{
  color: hsl(250, 50%, 50%);
  font-weight: bolder;
  text-decoration: underline;
}

.box{
  width:960px; 
  height: 300px;
  background: var(--clr-accent);
  border: .5rem solid var(--clr-primary);
  display:flex;
  justify-content: center;
  align-items: center;
 
}
.small{
  font-size:2rem !important;
}
.box ul li a{
  font-size: 2rem;
  color: black;
  text-decoration: none;
  
}
.box ul li a:hover{
  color:grey;
  text-decoration: underline;
}

.box ul li{
  list-style: none;
  padding: 0;
  margin:0;
}


.hero{
  background-color: #335;
  background-blend-mode: multiply;
  background-attachment: fixed;
  background-size: cover;
  text-align: center;
  color: white;
}

.server--info{
  list-style: none;
}

.spacer{
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.primary{
  background-color: var(--clr-primary)  !important;
  border: .5rem solid var(--clr-accent) !important;
}

.footer{
  display:flex;
  justify-content: center;
}
.footer-p {
  color: var(--clr-primary);
  font-size :2rem;
}

@media  screen and (max-width: 375px) {

    .wrapper{
      width: 362px;
    }
    h2.section-title{
      font-size: 2rem;
    }
    p.section-content{
      font-size: 1rem;
    }
    nav a{
      font-size: 1rem;
    }
    .hero{
      width: 375px;
    }
    .box{
      width: 362px;
     
    }
    .box ul li a{
      font-size: 1rem;
    }
    .box p{
      font-size: 1rem !important;
    }
  
}